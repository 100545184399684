import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { get, compact, last } from "lodash";
import PropTypes from "prop-types";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import moment from "moment";

import { ContentBlock } from "../../../components/Blocks";
import Pages from "../../../components/Pages";
import Breadcrumbs from "../../../components/Layout/Breadcrumbs";
import { makeUrl } from "../../../helpers/makeUrl";
import { Share } from "../../../widgets";
import { removeEmptyKeys, removePreloader } from "../../../helpers";
import {
	MetaDateModified,
	MetaDateDateCreated,
	MetaDatePublished,
	MetaDescription,
	MetaImage,
} from "../../../components/Meta";
import {
	ARTICLES_CONTENT,
	MEDIA_ARTICLES_MAX_UPDATE,
} from "../../../queries/queries.graphql";
import Contents from "../../../components/Contents";
import EmptyData from "../../../components/EmptyData";
import { useThemeContext } from "../../../components/Layout/ThemeContext";

export const query = graphql`
	query mediaArticlesPhotosDataQuery($slug: String!) {
		hasura {
			...ArticlesContent
		}
	}
`;

export default function MediaArticlePhotos({ location, data }) {
	const { theme } = useThemeContext();
	const dateNow = moment().format();
	const [photos, setPhotos] = useState(get(data, "hasura.media_articles[0]", {}));

	const path = last(compact(location.pathname.split("/")));
	const maxUpdated = new Date(get(data, "hasura.media_articles_aggregate.aggregate.max.updated_at", new Date()));

	const photosOptions = {
		variables: {
			location: path,
		},
		displayName: "MediaArticlePhotosQuery",
		fetchPolicy: "cache-and-network",
		ssr: false,
	};

	const photosOptionsNews = {
		variables: {
			location: path,
			dateNow,
		},
		displayName: "MediaArticlePhotosQuery",
		fetchPolicy: "cache-and-network",
		ssr: false,
	};

	const { loading: maxUpdateLoading, data: maxUpdateData, error: maxUpdateError } = useQuery(MEDIA_ARTICLES_MAX_UPDATE, photosOptions);

	const [loadPhoto, { called: photoCalled, loading: photoLoading, data: photoData, error: photoError }] = useLazyQuery(ARTICLES_CONTENT, photosOptionsNews);

	useEffect(() => {
		const currentMaxUpdated = new Date(get(maxUpdateData, "media_articles_aggregate.aggregate.max.updated_at", new Date()));

		if (maxUpdateData && !maxUpdateError) {
			if (+currentMaxUpdated !== +maxUpdated) {
				loadPhoto();
			} else {
				removePreloader();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load MediaArticlePhotosMaxUpdate", { maxUpdateError });
			removePreloader();
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (photoData && !photoError) {
			setPhotos(get(photoData, "media_articles[0]", photos));
			removePreloader(photoCalled);
		} else if (photoError) {
			console.error("Invalid load MediaArticlePhotosData", { photoError });
			removePreloader();
		}
	}, [photoLoading]);

	const slug = get(photos, "slug", "");
	const main_image_preview = get(photos, "main_image_preview", "");
	const main_image = get(photos, "main_image", "");
	const page_title_short = get(photos, "page_title_short", "");
	const page_title_full = get(photos, "page_title_full", "");
	const updated_at = get(photos, "updated_at", "");
	const title_full = get(photos, "title_full", "");
	const created_at = get(photos, "created_at", "");
	const content_blocks = get(photos, "content_blocks", []);

	const url = makeUrl.mediaPhoto({ slug });
	const imageSrc = get(main_image_preview, "src", get(main_image, "src", ""));

	const breadcrumbsData = {
		item: {
			title_full: "медиатека",
			slug: "media",
		},
		item2: {
			title_full: "фоторепортажи",
		},
	};

	return photos ? (
		<Pages entity={photos} url={url} >
			<div itemScope itemType="https://schema.org/ImageGallery">
				<MetaDateModified content={updated_at} />
				<MetaDateDateCreated content={created_at} />
				<MetaDatePublished content={created_at} />
				<MetaDescription content={title_full} />
				<MetaImage content={imageSrc} />
				<ContentBlock key={"breadcrumbs"}>
					<div className="container">
						<Breadcrumbs
							currentLabel={get(photos, "page_title_short", "")}
							pageContext={breadcrumbsData}
						/>
					</div>
				</ContentBlock>
				<ContentBlock key={"gallery"}>
					<div className={"container"}>

						<h1 className={"h1"}
							itemProp="headline"
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.h1,
								lineHeight: theme.lineHeight.h1,
								color: theme.color.text,
							})}
						>
							{page_title_full}
						</h1>

						<Contents items={content_blocks} date={updated_at} articleBody={"articleBody"}/>

					</div>
				</ContentBlock>
				<div className={"container"}>
					<Share
						url={url}
						pageTitleShort={page_title_short}
						pageTitleFull={page_title_full}
						imageSrc={imageSrc}
					/>
				</div>
			</div>
		</Pages>
	) : (
		<EmptyData />
	);
}

MediaArticlePhotos.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
	pageContext: PropTypes.object,
};

MediaArticlePhotos.defaultProps = {
	data: {},
	location: {
		pathname: "",
	},
	pageContext: {},
};
